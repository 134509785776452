const {fr} = require("date-fns/locale");
module.exports = Object.assign({dateFnsLocale: fr}, {
  "date_format": {
    "fr": "d MMMM yyyy"
  },
  "notifications_service_endpoint": "https://apim.keolis.com/SIK/000/notification",
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "chapo_length_number": 559,
  "search_token": "78a74a5f9a89dfbd8091804d0cc534",
  "v3_share_label": {
    "fr": "Je partage"
  },
  "serverless_sendmail_endpoint": "https://apim.keolis.com/dato/send/form/instit/",
  "site_name": "Keolis-lyon.com",
  "hour_format": {
    "fr": "HH:mm"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/SIK/000/file/import HTTP/1.1",
  "piano_site_id": "639194",
  "v3_social_networks": [
    "FE3YKOrbTHCx9qzAmQvx1g",
    "GGWkFCxhT2aimdIkP5SNxA",
    "OAaMbt7hQAuuAAPYsNH60A",
    "N7k_kh41QFGY-yYiK5b7EQ"
  ],
  "facebook_link": "https://www.facebook.com/sharer/sharer.php?u=",
  "notifications_service_token": "2d5392f4a9404b2690515b92a3eadc93",
  "google_tag_manager_id": "GTM-P6F27BV",
  "meta_description": {
    "fr": "Keolis Lyon exploite le réseau de Transports en Commun Lyonnais"
  },
  "serverless_sendmail_token": "1142321004f3407b98a95856f9820607",
  "twitter_link": "https://twitter.com/intent/tweet?url=",
  "google_analytics_key": "387551283",
  "subsidiary_logo": {
    "fr": {
      "path": "/92092/1673456791-keolis-lyon-4c-hd_rvb.jpg",
      "format": "jpg",
      "size": 111903,
      "alt": "Keolis Lyon",
      "title": null,
      "width": 1536,
      "height": 473
    }
  },
  "linkedin_link": "https://www.linkedin.com/sharing/share-offsite/?url=",
  "subsidiary_reduced_logo": {
    "fr": {
      "path": "/92092/1724158649-logo_k_bleu-size5.svg",
      "format": "svg",
      "size": 754,
      "alt": "Logo Keolis",
      "title": "Keolis",
      "width": 256,
      "height": 256
    }
  },
  "mailto_addresse": "mailto:someone@example.com",
  "home_page_title": {
    "fr": "Accueil"
  },
  "share_buttons_title": {
    "fr": "Partager"
  },
  "transport_mode_title": {
    "fr": "Modes de transport"
  },
  "facebook_label": {
    "fr": "Facebook"
  },
  "token_google_site_verification": "",
  "twitter_label": {
    "fr": "Twitter"
  },
  "linkedin_label": {
    "fr": "LinkedIn"
  },
  "mailto_label": {
    "fr": "Partager par mail"
  },
  "download_label": {
    "fr": "Télécharger"
  },
  "updated_at": "2024-12-04T18:11:19.163+01:00",
  "created_at": "2021-04-20T17:40:04.593+02:00",
  "locale": "fr",
  "locales": [
    "fr"
  ],
  "siteUrl": "https://www.keolis-lyon.com/"
})